<template>
  <div class="newBox">
    <div class="tab" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logoBlack.png" alt="">
    </div>
    <div class="box">
      <div class="banner">
        <div id="login" :class="{ shaking: toShake}">
          <img src="/activity/voucher2024/other.png" alt="">
          <div class="interactive">
            <input v-model="form.phone" type="text" placeholder="请输入手机号" @blur="changePhone">
            <div class="codeBox">
              <input v-model="form.code" type="text" placeholder="请输入验证码">
              <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
              <span v-else>重新获取({{verSecond}})</span>
            </div>
            <button @click="submit(form)">一键领取</button>
          </div>
          <span class="dollar">—— 更有人民币红包等你来拿 ——</span>
          <div v-if="controlImgCodeDialog" class="codeDialog">
            <img :src="imgcodelocal" alt="">
            <p>
              <span>图形验证码:</span>
              <input type="text" v-model="form.codeNumber">
            </p>
            <div>
              <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
              <button @click="confirm(form.codeNumber)">确定</button>
            </div>
          </div>
        </div>
      </div>
      <div class="part2">
        <p @click="rulesModel = true"></p>
        <img class="titImg" src="/activity/voucher2024/tit1.png" alt="">
        <img class="titImg" src="/activity/voucher2024/tit2.png" alt="">
        <div class="tit3">
          <img @click="live800" src="/activity/voucher2024/button.png" alt="">
        </div>
      </div>
    </div>
    <div class="sliderBox" v-if="slider">
      <div class="slider">
        <canvas class="qrcode" ref="qrcodeCanvas"></canvas>
        <!-- <img class="qrcode" src="../../../../static/appDownload/code.png" alt=""> -->
        <!-- <div>
          <span>开户就领</span>
          <p>
            <span>$500</span>
            <span>体验金</span>
          </p>
        </div> -->
      </div>
      <img @click="()=>{slider = false}" src="/activity/voucher/closer.png" alt="">
    </div>
    <div class="mask" v-if="rulesModel">
      <div class="popup">
        <img
          class="guanbi1"
          @click="rulesModel = false"
          src="../../../../static/common/closer.png"
          alt=""
        />
        <ul class="dialogContent">
          <h3>活动规则</h3>
          <li>
            <span>活动对象：</span><br/>
            活动期间，在本平台首次开立真实账户的用户
          </li>
          <li>
            <span>活动时间：</span><br/>
            即日起至{{ getLastDayOfMonth() }}
          </li>
          <li>
            <span>活动期间：</span><br/>
            开户领$588美元赠金<br />
            用户完成首次开户 领$588美元赠金，有效期90天内每交易一手伦敦金/伦敦银，获得相对应的赠金金额返还（未满一手按照实际交易手数占一手的比例计算）

          </li>
          <li>
            <span>奖励明细：</span><br/>
            <!-- 1. 活动期间，开立真实账户赠送500美元现金券一张；<br/>
            2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br/>
            3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br />
            4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br/>
            5. 盈利有效期为平仓后30天内，逾期扣回；<br/>
            6. 现金券仅限伦敦金/伦敦银交易品种。 -->
            <img
              src="/activity/voucher/table.png"
              alt=""
            /><br/>
            用户可登录App-我的-赠金奖励中查看
          </li>
          <li>
            <span>注意事项：</span><br/>
            1. 客户参与活动需在本公司开立真实交易账户；参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br/>
            2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br/>
            3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br/>
            4. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { verifyImgCode, quickRegister, maidianOpenreal } from '../../../../api/info';
import { getGuid } from '../../../../utils/guid';
import { generateQRCode } from "../../../../utils/tools";
export default {
  name:'voucherNewPc',
  data(){
    return{
      form:{
        phone:'',
        code:'',
        codeNumber:'',
      },
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      guid:'',
      // 是否抖动
      toShake: false,
      scroll: '',
      navBarFixed:false,
      slider:true,
      rulesModel: false,
      packageid: '',
    }
  },
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', true);
    if(document.body.clientWidth<500){
      // window.location.href = 'http://localhost:8081/#/activity/voucher/wap';
    }
    this.packageid = this.$route.query.channel || this.$route.query.v || '';
    maidianOpenreal({ eventname: 'z1', eventdescribe: '真实用户_访客数据', packageid: this.packageid });
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    const { origin } = window.location;
    const channel = sessionStorage.getItem('channel');
    let url = origin + '/subject/download?channel=' + channel;
    const icon = require('../../../../static/index/hongshi.jpg')
    generateQRCode(url, this.$refs.qrcodeCanvas, icon)
  },
  methods:{
    getLastDayOfMonth() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const lastDayOfMonth = new Date(year, month, 0);
      // 获取最后一天的日期
      const lastDate = lastDayOfMonth.getDate();
      return year + '年' + month + '月' + lastDate + '日'
    },
    // 输入手机号
    changePhone(e) {
      if(e.target.value) {
        maidianOpenreal({
          eventname: 'rr2',
          eventdescribe: '真实用户_开户填写手机号',
          detail: {
              mobile: e.target.value,
          },
          eventtype: 'input',
          packageid: this.packageid
        });
      }
    },
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
          Appcode: this.packageid,
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.IsSuccess){
            this.$message({
              message: res.Data,
              type: 'success'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
            maidianOpenreal({ eventname: 'rr3', eventdescribe: '真实用户_发送验证码', eventtype: 'input', packageid: this.packageid });
          }else{
            this.$message({
              message: res.Data,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
          Channel: this.packageid
        };
        maidianOpenreal({ eventname: 'rr4', eventdescribe: '真实用户_提交资料数', eventtype: 'input', packageid: this.packageid });
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            maidianOpenreal({
              eventname: 'rr5',
              eventdescribe: '真实开户_开户成功数',
              usertype: '2',
              medium: '',
              plan: '',
              account: res.data.Account,
              accounttype: 2,
              packageid: this.packageid,
            });
            setTimeout(() => {
              window.location.href = 'https://www.rlcvipltd.net/uc/login';
            }, 500)
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
        this.handleShake();
      }
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 控制抖动
    handleShake() {
      this.toShake= true
      // demo for next animation.
      setTimeout(() => {
        this.toShake= false
      }, 1000)
    },
    live800(){
      // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377')
      window.open(this.$parent.live800Url)
    },
    goLink(){
      window.open('https://www.rlcvipltd.net/uc/signUp/real');
    },
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
  },
}
</script>

<style lang="less" scoped>
.newBox{
  .tab{
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    img{
      width: 130px;
    }
  }
  .navBarFixed{
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .box{
    width: 100%;
    background: #F5A06D;
    .banner{
      width: 100%;
      height: 1283px;
      background: url('/activity/voucher2024/banner.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-right: 15%;
        #login{
        width: 514px;
        height: 514px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-top: 10px;
        position: relative;
        top: -300px;
        border-radius: 22px;
box-shadow: 6.82px 7.31px 24px 10px rgba(125,119,247,0.11); 
        p{
          font-size: 32px;
          font-weight: 600;
          color: #ff8038;
          margin: 10px auto;
        }
        img{
          margin: 15px 0 35px 0;
        }
        .interactive{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          input:focus{
            outline: none;
          }
          input{
            width: 80%;
            height: 60px;
            background: #fff;
            border: 2px solid #ff8038;
            border-radius: 18px;
            font-size: 16px;
            margin-bottom: 30px;
            text-indent: 15px;
            list-style: none;
          }
          .codeBox{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
              width: 160px;
              height: 65px;
              line-height: 65px;
              font-size: 16px;
              border-radius: 18px;
              background-color: #ff8038;
              text-align: center;
              color: #fff;
              position: absolute;
              top: 1px;
              right: 49px;
              cursor: pointer;
            }
          }
          button{
            width: 72%;
            height: 65px;
            background-color: #E74635;
            border: 1px solid #E74635;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 24px;
            cursor: pointer;
          }
        }
        .dollar{
          font-size: 20px;
          color: #E74635;
          margin-top: 20px;
        }
        .codeDialog{
          width: 400px;
          height: 200px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background-color: #fef6e9;
          border-radius: 15px;
          z-index: 99;
          border: 1px solid #ffd8a6;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          img{
            width: 120px;
            margin-bottom: 0;
          }
          p{
            span{
              color: #7f78fa;
              font-size: 18px;
              font-weight: 600;
            }
            input{
              width: 150px;
              height: 30px;
              background-color: #ffffff;
              border: none;
              margin-left: 10px;
              text-indent: 10px;
              color: #7f78fa;
            }
          }
          div{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
              width: 100px;
              height: 40px;
              border: 1px solid #ff8038;
              background-color: #ffffff;
              color: #ff8038;
              font-size: 18px;
              border-radius: 8px;
              margin: 0 10px;
            }
            button:nth-child(2){
              background-color: #ff8038;
              color: #ffffff;
            }
          }
        }
      }
      .shaking {
        animation-delay: 0s;
        animation-name: shake;
        animation-duration: 0.1s;
        animation-iteration-count: 3;
        animation-direction: normal;
        animation-timing-function: linear;
      }
      @keyframes shake {
        0% {
          margin-left: 0px;
          margin-right: 3px;
          margin-top: 0px;
        }
        100% {
          margin-left: 3px;
          margin-right: 0px;
          margin-top: 3px	;
        }
      }
    }
    .part2{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding-top: 65px;
      position: relative;
    margin-top: -400px;
      p{
        position: absolute;
        top: 11.5%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
         width: 80px;
        height: 30px;
      }
      .titImg{
        margin-bottom: 60px;
      }
      .tit3{
        width: 1218px;
        height: 287px;
        background: url('/activity/voucher2024/tit3.png') no-repeat;
        background-size: cover;
        position: relative;
        img{
          position: absolute;
          bottom: 50px;
          left: 15.5%;
          cursor: pointer;
        }
      }
    }
  }
  .sliderBox{
    position: fixed;
    top: 50%;
    right: -115px;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .slider{
      width: 230px;
      height: 364px;
      background: url('/activity/voucher/slider.png') no-repeat;
      background-size: cover;
      margin-bottom: 30px;
      position: relative;
      .qrcode{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 150px !important;
        height: 150px !important;
      }
      div{
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #331d85;
        font-weight: 600;
        font-size: 18px;
        p{
          margin: 5px auto 0;
          span:nth-child(1){
            color: #ff8038;
          }
        }
      }
    }
    img{
      cursor: pointer;
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
  }
  .popup {
    width: 900px;
    height: 750px;
    border-radius: 10px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%;-46%);
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
    overflow-y: scroll;
    z-index: 99999999999999;
    .guanbi1 {
      float: right;
      cursor: pointer;
      width: 20px;
      margin-top: 8px;
      margin-right: 10px;
    }
    .dialogContent {
      box-sizing: border-box;
      padding: 30px 50px 20px;
      h3{
        text-align: center;
        margin: -15px auto 20px;
        font-size: 22px;
        color: #6a32aa;
      }
      li {
        font-size: 16px;
        color: rgb(34, 33, 33);
        line-height: 36px;
        span {
          font-weight: 600;
          font-size: 18px;
          color: #6a32aa;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
}
</style>